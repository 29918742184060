/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { BsEmojiSmile, BsXLg } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Dialog, Loader, Provider } from "@fluentui/react-northstar";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  useHamburgerMenuContext,
  useMenuContext,
} from "../../context/menucontext/menuContext";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { UseNotificationContext } from "../../context/notificationcontext/notificationContext";
import { getData, postData } from "../../services/customApis";
import { timeAgo } from "../../utility/timefunctions";
import APIEndPoints from "../../utility/apiendpoints";
import Constant from "../../utility/constants";
import { navigateBackToHome } from "../../utility/redirectToHome";
import { checkIfDeviceIsMob } from "../../utility/utility";
import NotificationSettings from "./notificationSettings";
import UnauthorizedPage from "../../commonui/unauthorizedpage/unauthorized";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import LinkComponent from "./linkComponent";
import ActionButton from "./actionButton";
import "./notification.css";

function Notification(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
  const { notifications, handleNotifications } = UseNotificationContext();
  const { menuState, handleChangeNav } = useMenuContext();
  const { userInfo } = UseUserInfoContext();

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [page, setPage] = useState(0);
  const [fromDeeplink, setFromDeeplink] = useState(false);
  const [type, setAlertMessageType] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const isModelOpen = props.isModelOpen;

  const [hasMoreNotifications, setHasMoreNotifications] = useState(
    !isModelOpen
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [headerText, setHeaderText] = useState("");
  const [detailsText, setDetailsText] = useState("");

  let noNotifications = notifications.list.length < 1;
  const target = useRef("null");

  const [prevMenu, setPrevMenu] = useState({});
  useEffect(() => {
    setPrevMenu(menuState);
    if (!isModelOpen) {
      handleNotifications({
        isFullPageNotification: true,
      });
      handleChangeNav({
        section: {},
        menu: {},
        submenu: {},
        data: "",
        to: "",
      });
      // fetchData();
      window.scrollTo(0, 0);
    }

    if (toggleHamburger && !isModelOpen) {
      handleHamburgerChange();
    }
    return () => {
      if (!isModelOpen) {
        handleNotifications({
          isFullPageNotification: false,
        });
      }
    };
  }, []);

  useEffect(() => {
    if (!isModelOpen && userInfo.teams.companyId !== null) {
      setHasMoreNotifications(true);
      handleNotifications({
        isFetching: true,
        list: [],
        reloadTheNotification: false,
      });
      //console.log("WITHOUTRELOAD RELOADDDDD");
      setPage((prevPage) => 0);
      fetchData();
    }
  }, [userInfo.teams]);

  useEffect(() => {
    if (!isModelOpen) {
      if (notifications.reloadTheNotification) {
        setHasMoreNotifications(true);
        handleNotifications({
          isFetching: true,
          list: [],
          reloadTheNotification: false,
        });
        //console.log("FROM RELOADDDDD");
        fetchData(true);
      }
    }
  }, [notifications.reloadTheNotification]);

  const handleCloseNotifications = () => {
    handleChangeNav({
      ...prevMenu,
    });
    if (!toggleHamburger) {
      handleHamburgerChange();
    }
    //handleHamburgerChange();
    if (!fromDeeplink) {
      navigate(-1);
    } else {
      // window.location.href = Constant.REDIRECTION_PATH;
      navigateBackToHome();
    }
  };

  const handleMarkAllAsRead = async () => {
    let res = await postData(
      {
        notificationId: 0,
        wasRead: true,
        deleted: false,
      },
      APIEndPoints.UPDATE_NOTIFICATION(userInfo?.teams?.key)
    );
    // console.log("res", res);

    handleNotifications({
      list: notifications.list.map((item) => ({
        ...item,
        wasRead: true,
      })),
      count: res?.data?.notificationCount,
    });
  };

  // const handleMarkAllAsUnread = async () => {
  //   let res = await postData(
  //     {
  //       notificationId: 0,
  //       wasRead: false,
  //       deleted: false,
  //     },
  //     APIEndPoints.UPDATE_NOTIFICATION(userInfo?.teams?.key)
  //   );
  //   // console.log("res", res);

  //   handleNotifications({
  //     list: notifications.list.map((item) => ({
  //       ...item,
  //       wasRead: false,
  //     })),
  //     count: res.data.notificationCount,
  //   });
  // };

  const handleDeleteAll = async () => {
    let res = await postData(
      {
        notificationId: 0,
        wasRead: true,
        deleted: true,
      },
      APIEndPoints.UPDATE_NOTIFICATION(userInfo?.teams?.key)
    );
    // console.log("res", res);

    if (!noNotifications) {
      handleNotifications({
        list: [],
        count: res?.data?.notificationCount,
      });
    }
  };

  const handleDelete = async (id) => {
    let res = await postData(
      {
        notificationId: id,
        wasRead: true,
        deleted: true,
      },
      APIEndPoints.UPDATE_NOTIFICATION(userInfo?.teams?.key)
    );
    // console.log("res", res);

    handleNotifications({
      list: notifications.list.filter((item) => item.notificationId !== id),
      count: res?.data?.notificationCount,
      fetchOnDelete: isModelOpen,
    });
  };

  const handleMarkAsRead = async (id) => {
    // console.log(APIEndPoints.UPDATE_NOTIFICATION(userInfo?.teams?.key));
    let res = await postData(
      {
        notificationId: id,
        wasRead: true,
        deleted: false,
      },
      APIEndPoints.UPDATE_NOTIFICATION(userInfo?.teams?.key)
    );
    // console.log("res", res);

    handleNotifications({
      list: notifications.list.map((item) =>
        item.notificationId === id ? { ...item, wasRead: true } : item
      ),
      count: res?.data?.notificationCount,
    });
  };

  const handleMarkAsUnread = async (id) => {
    // console.log(APIEndPoints.UPDATE_NOTIFICATION(userInfo?.teams?.key));
    let res = await postData(
      {
        notificationId: id,
        wasRead: false,
        deleted: false,
      },
      APIEndPoints.UPDATE_NOTIFICATION(userInfo?.teams?.key)
    );
    // console.log("res", res);

    handleNotifications({
      list: notifications.list.map((item) =>
        item.notificationId === id ? { ...item, wasRead: false } : item
      ),
      count: res?.data?.notificationCount,
    });
  };

  const fetchData = (reloadTheNotifications) => {
    // setPage((prevPage) => prevPage + 1);
    // console.log("fetching it", notifications);
    let pageCount = reloadTheNotifications ? 1 : page + 1;
    let pageVar = reloadTheNotifications ? "10" : "10";
    let endPoint = "";
    if (location.state != null) {
      if (location.state.companyId && userInfo.teams.companyId) {
        endPoint =
          APIEndPoints.COMPANY_NOTIFICATIONS_LIST +
          "?companyId=" +
          location.state.companyId +
          "&page=" +
          pageCount +
          "&pageSize=" +
          pageVar;
        location.state = null;
        setFromDeeplink(true);
      }
    } else {
      endPoint =
        APIEndPoints.COMPANY_NOTIFICATIONS_LIST +
        "?companyId=" +
        userInfo?.teams?.key +
        "&page=" +
        pageCount +
        "&pageSize=" +
        pageVar;
    }
    // ------replace below api with number of unread notifications api
    handleNotifications({
      errorApiCall: false,
      accessDenied: false,
    });
    !isModelOpen &&
      getData(endPoint)
        .then((res) => {
          if (res?.data?.notifications.length < 1) {
            setHasMoreNotifications(false);
            handleNotifications({
              count: res?.data?.notificationCount,
              isFetching: false,
            });
            return;
          }
          let notificationsLists = notifications.list;
          notificationsLists.push.apply(
            notificationsLists,
            res?.data?.notifications
          );

          handleNotifications({
            count: res?.data?.notificationCount,
            isFetching: false,
            list: reloadTheNotifications
              ? res?.data?.notifications
              : notificationsLists,
          });
          return res;
        })
        .then((res) => {
          if (!reloadTheNotifications) {
            res?.data?.notifications.length < 10 &&
              setHasMoreNotifications(false);
            setPage((prevPage) => {
              return prevPage + 1;
            });
          } else {
            res?.data?.notifications.length < 10 &&
              setHasMoreNotifications(false);
            setPage(1);
          }
        })
        .catch((error) => {
          handleNotifications(
            error?.response?.data?.Errors?.[0]?.toLowerCase() ==
              "access denied."
              ? {
                  accessDenied: true,
                  count: 0,
                }
              : {
                  errorApiCall: true,
                  count: 0,
                }
          );
        })
        .finally(() => {
          handleNotifications({
            isFetching: false,
          });
        });
  };
  const handleHeader = (item) => {
    if (item.details === null) {
      return item.header;
    } else {
      return (
        <div>
          {item.header}
          <span
            // onClick={() => handleSeeDetails(item.header, item.details)}
            onClick={() => {
              handleSeeDetails(item.header, item.details);
              setTimeout(() => {
                const divsWithDeleteNotifications = document.querySelectorAll(
                  "div.deleteNotificationsStyles"
                );

                divsWithDeleteNotifications.forEach((div) => {
                  const parentDiv = div?.parentNode?.parentElement;
                  if (parentDiv) {
                    parentDiv.style.zIndex = "9999999";
                    parentDiv.style.position = "absolute";
                  }
                });
              }, 50);
            }}
            style={{
              cursor: "pointer",
              color: "blue",
              textDecoration: "underline",
            }}
          >
            {" "}
            See details
          </span>
        </div>
      );
    }
  };
  const handleSeeDetails = (header, details) => {
    setOpenDialog(true);
    setHeaderText(header);
    let parsedDetails = [];

    const detailsArray = details?.split(",");
    if (detailsArray.length > 1) {
      setDetailsText(
        detailsArray.map((item, index) => <li key={index}>{item.trim()}</li>)
      );
    } else {
      setDetailsText(details);
    }

    // try {
    //   parsedDetails = JSON.parse(details);
    // } catch (error) {
    //   console.error('Failed to parse details:', error);
    // }
    // if(parsedDetails?.length > 0)
    //   {
    //    setDetailsText(
    //     parsedDetails?.map((item, index) => (
    //       <li key={index}>{item}</li>
    //     ))
    //    )
    //   };
    // console.log("openDialog", openDialog);
  };
  const handleCancel = () => {
    setOpenDialog(false);
    setHeaderText("");
    setDetailsText("");
    // console.log("openDialog", openDialog);
  };

  const handleDownload = (res) => {
    const fileDetails = JSON.parse(res);
  };

  return (
    <div data-testid="notification_component">
      {!isModelOpen && (
        <div
          className="common_container_header notificationHeader"
          id="common_container_header"
        >
          <header>
            <p className="common_title">
              Notifications
              {!noNotifications && `(${notifications.count})`}
              <BsXLg
                title="Close"
                className="notifications-close"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleCloseNotifications();
                  }
                }}
                onClick={() => handleCloseNotifications()}
              />
            </p>
          </header>
        </div>
      )}
      {!notifications.accessDenied ? (
        <div
          className={`notifications-component ${
            isModelOpen ? "sidebar-view" : "full-screen-view"
          }`}
          style={
            isModelOpen
              ? { padding: "10px 0", fontSize: "12px", margin: "" }
              : {}
          }
        >
          <div className="showAll_deleteAll_btn">
            <Link
              to={`${!noNotifications ? "/notifications" : "#"}`}
              className={`showAllNotification ${
                noNotifications && "disable-notifications"
              }`}
              style={!isModelOpen ? { visibility: "hidden" } : {}}
              onClick={() => {
                if (!noNotifications) {
                  handleNotifications({
                    isPanelOpen: false,
                    list: [],
                    isFetching: true,
                  });
                }
              }}
            >
              Show notifications
            </Link>
            <div
              tabIndex={0}
              style={!isModelOpen ? { visibility: "hidden" } : {}}
              className={`deleteAllNotification ${
                noNotifications && "disable-notifications"
              }`}
              onClick={() => (!noNotifications ? handleMarkAllAsRead() : "")}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !noNotifications) {
                  handleMarkAllAsRead();
                }
              }}
            >
              Mark all as read
            </div>
            <Dialog
              // target={target.current}
              open={confirmDelete}
              onOpen={() =>
                !noNotifications
                  ? setConfirmDelete(true)
                  : setConfirmDelete(false)
              }
              onCancel={() => setConfirmDelete(false)}
              onConfirm={() => {
                setConfirmDelete(false);
                handleDeleteAll();
              }}
              closeOnOutsideClick={false}
              cancelButton="Cancel"
              confirmButton="Delete All"
              content=<p>
                <br />
                Are you sure you want to delete all notifications? <br />
              </p>
              header="Delete Notifications"
              headerAction={{
                icon: <BsXLg />,
                title: "Close",
                onClick: () => setConfirmDelete(false),
              }}
              trigger={
                <div
                  tabIndex={0}
                  className={`deleteAllNotification ${
                    noNotifications && "disable-notifications"
                  }`}
                  // ref={target}
                  onClick={() => {
                    setTimeout(() => {
                      const divsWithDeleteNotifications =
                        document.querySelectorAll(
                          "div.deleteNotificationsStyles"
                        );

                      divsWithDeleteNotifications.forEach((div) => {
                        const parentDiv = div?.parentNode?.parentElement;
                        if (parentDiv) {
                          parentDiv.style.zIndex = "9999999";
                          parentDiv.style.position = "absolute";
                        }
                      });
                    }, 50);
                  }}
                >
                  Delete all
                </div>
              }
              className="deleteNotificationsStyles"
            />
          </div>
          {notifications.isFetching ? (
            <div style={{ marginTop: "100px" }}>
              <Loader />
            </div>
          ) : !notifications.errorApiCall ? (
            <>
              <NotificationSettings
                alertMessage={alertMessage}
                setAlertMessage={setAlertMessage}
                type={type}
                setAlertMessageType={setAlertMessageType}
              />
              {!noNotifications ? (
                <InfiniteScroll
                  dataLength={notifications.list.length} //This is important field to render the next data
                  next={fetchData}
                  hasMore={hasMoreNotifications}
                  loader={
                    <div
                      style={{
                        marginTop: "10px",
                        height: "60px",
                      }}
                    >
                      <Loader />
                    </div>
                  }
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      {!isModelOpen && <b>You have seen it all</b>}
                    </p>
                  }
                >
                  {notifications.list
                    .slice(0, isModelOpen ? 5 : undefined)
                    .map((item, index) => {
                      return (
                        <div
                          className={`notifications__container ${
                            item.wasRead ? "" : "notification_is_unread"
                          }`}
                          style={isModelOpen ? { padding: "0px 10px" } : {}}
                          key={index}
                        >
                          {!item.wasRead && (
                            <div
                              className="unread_dot"
                              style={
                                isModelOpen
                                  ? {
                                      margin: "10px 10px 10px 0 ",
                                      height: "12px",
                                    }
                                  : {}
                              }
                            ></div>
                          )}
                          <div tabIndex={0} className="notification">
                            <div className="notification_title">
                              {item.type}
                            </div>
                            <div className="notification_body">
                              {item?.formatValues?.length > 0 ? (
                                <LinkComponent notification={item} />
                              ) : (
                                handleHeader(item)
                              )}
                            </div>
                            <div className="notification_footer">
                              <div className="notification_time">
                                {timeAgo(item.createdDate)}
                              </div>
                              <div
                                className="notification_read_delete_btns"
                                style={isModelOpen ? { margin: 0 } : {}}
                              >
                                {item.wasRead ? (
                                  <div
                                    tabIndex={0}
                                    className="mark_unread_btn"
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handleMarkAsUnread(item.notificationId);
                                      }
                                    }}
                                    onClick={() =>
                                      handleMarkAsUnread(item.notificationId)
                                    }
                                  >
                                    Mark as unread
                                  </div>
                                ) : (
                                  <div
                                    tabIndex={0}
                                    className="mark_read_btn"
                                    onKeyDown={(e) => {
                                      if (e.key === "Enter") {
                                        handleMarkAsRead(item.notificationId);
                                      }
                                    }}
                                    onClick={() =>
                                      handleMarkAsRead(item.notificationId)
                                    }
                                  >
                                    Mark as read
                                  </div>
                                )}

                                <div
                                  tabIndex={0}
                                  className="mark_delete_btn"
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                      handleDelete(item.notificationId);
                                    }
                                  }}
                                  onClick={() =>
                                    handleDelete(item.notificationId)
                                  }
                                >
                                  Delete
                                </div>
                                {item?.actionUrl &&
                                  !checkIfDeviceIsMob() &&
                                  (() => {
                                    let actionUrl;
                                    let payload;
                                    try {
                                      actionUrl = JSON.parse(item?.actionUrl);
                                      payload = JSON.parse(actionUrl?.Payload);
                                    } catch (error) {
                                      console.error(
                                        "Failed to parse JSON:",
                                        error
                                      );
                                      payload = {};
                                    }
                                    return (
                                      <ActionButton
                                        item={item}
                                        alertMessage={alertMessage}
                                        setAlertMessage={setAlertMessage}
                                        type={type}
                                        payload={payload}
                                        setAlertMessageType={
                                          setAlertMessageType
                                        }
                                        isModelOpen={isModelOpen}
                                      />
                                    );
                                  })()}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  {
                    <DialogBox
                      cancelButton={"Close"}
                      onCancelMethod={() => {
                        handleCancel(false);
                      }}
                      closeOnOutsideClick={false}
                      content={
                        <p className={"validationContent"}>
                          <br />
                          {headerText}
                          <br />
                          <br />
                          <ul>
                            <li>{detailsText}</li>
                          </ul>
                          <br />
                          <p style={{ fontStyle: "italic", fontSize: "13px" }}>
                            {
                              Constant.DI_APP_CONSTANTS
                                .FURTHER_ASSISTANCE_FOOTER
                            }
                          </p>
                        </p>
                      }
                      headerName={"Metadata validation failed"}
                      headerActionName={{
                        icon: <GrClose />,
                        title: "Close",
                        onClick: () => {
                          handleCancel(false);
                        },
                      }}
                      backdrop={true}
                      openDialog={openDialog}
                      className={"dialogStyles deleteNotificationsStyles"}
                    />
                  }
                </InfiniteScroll>
              ) : (
                <div className="no-notifications">
                  <BsEmojiSmile />
                  <p>You have no notifications right now.</p>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="no-notifications" style={{ fontSize: "15px" }}>
                <p style={{ fontWeight: "bold" }}>Something went wrong</p>
                <p>please refresh the page</p>
              </div>
            </>
          )}
        </div>
      ) : (
        <UnauthorizedPage />
      )}
    </div>
  );
}

export default Notification;
