import React, { useEffect, useState, useMemo } from "react";
import "./bulletinBoard.css";
import WelcomeScreen from "./welcomescreen/welcomeScreen";
import BulletinCard from "./bulletincard/bulletinCard";
import { UseUserInfoContext } from "../../context/usercontext/userContext";
import { postData, getDataById } from "../../services/customApis";
import APIEndPoints from "../../utility/apiendpoints";
import Constant from "../../utility/constants";
import { app } from "@microsoft/teams-js";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { Divider, Loader } from "@fluentui/react-northstar";
import {
  useHamburgerMenuContext,
  useMenuContext,
} from "../../context/menucontext/menuContext";
import sideNavMainMenuData from "../../data/menu/sideNavMainMenuData";
import { getDateFromUTC3 } from "../../utility/timefunctions";
import find from "lodash/find";
import reject from "lodash/reject";
import {
  getContentTypeNamesById2,
  getUrlParams,
  updateMetaDataCounts,
  getContentTypePathsById,
  openPdfInNewTab,
} from "../../utility/utility";
import DialogBox from "../../commonui/dialogBox/DialogBox";
import UnauthorizedPage from "../../commonui/unauthorizedpage/unauthorized";
import TelemetryEventNames from "../../data/apptelemetry/appTelemetry.const";
import { GrClose } from "react-icons/gr";
import { bulletinPinnedResponse } from "../../data/bulletinboard/bulletinBoardPinCardResponse";
import { TrackEvent } from "../../services/appInsights";
import { useLocation } from "react-router-dom";
import DeepLinkPopup from "../../components/polls/pollsCurationForm/pollPopup";
import { scrollIntoViewWithOffset } from "../../utility/menuscroll";
import { UseLibrarySearchFormContext } from "../../../src/context/librarysearchformcontext/librarySearchFormContext";
import { UseSearchAndFilterColumnContext } from "../../../src/context/searchandfiltercolumncontext/searchAndFilterColumnContext";
import { useMsal } from "@azure/msal-react";
import ReusableLoader from "../../commonui/reusableloader/reusableLoader";
import { UseContactUsContext } from "../../context/contactuscontext/contactUsContext";

const BulletinBoard = () => {
  const location = useLocation();
  const { userInfo } = UseUserInfoContext();
  const { handleChangeNav, menuState } = useMenuContext();
  const { handleNotificationMsgsData } = UseToasterNotificationsContext();
  const { toggleHamburger, handleHamburgerChange } = useHamburgerMenuContext();
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(true);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [scrolledCardId, setScrolledCardId] = useState();
  const [errorAccessDenied, setAccessDenied] = useState(false);
  const { librarySearchFormJsonData, handleLibrarySearchFormJsonData } =
    UseLibrarySearchFormContext();
  const { searchAndFilterColumnsData, handleSearchAndFilterColumnsData } =
    UseSearchAndFilterColumnContext();
  const [bulletinDetails, setBulletinDetails] = useState({
    cardData: [],
    pageLength: 6,
    pageNumber: 1,
    pinMetaData: [],
  });
  const { instance } = useMsal();
  const { menuInfo, handleMenuInfo } = UseContactUsContext();

  useEffect(() => {
    if (
      userInfo?.teams?.featureFlagsObj["Bulletin Board"]?.isEnabled === true
    ) {
    }
    getDataById(
      "",
      APIEndPoints.GET_PAGE_LEVEL_FLAGS(userInfo?.teams?.companyId, 10)
    )
      .then(async (res) => {
        getUrlParams("backButton") ? getDataFromCache() : getDataFromAPI();
        setSideBarMenu();
        TrackEvent(TelemetryEventNames.UCC_BB_VIEW_EVENT, userInfo, {
          SectionName: TelemetryEventNames.SECTION_TYPE_BULLETIN_BOARD,
        });
      })
      .catch(async (error) => {
        if (
          error?.response?.data?.Errors?.[0]?.toLowerCase() == "access denied."
        ) {
          setAccessDenied(true);
          setIsFetching(false);
        } else {
          getDataFromAPI();
        }
      });
  }, [userInfo.teams.companyId, userInfo.displayName]);

  useEffect(() => {
    !isFetching && fetchData();
  }, [bulletinDetails.pageNumber]);
  // useEffect(() => {
  //     !isFetching && bulletinDetails.cardData?.length < 6 && hasMoreData && handleBulletinDetails({
  //         pageNumber: bulletinDetails.pageNumber + 1
  //     });
  // }, [bulletinDetails.pinMetaData]);

  useEffect(() => {
    setSideBarMenu();
    if (toggleHamburger == false && window.innerWidth > 900) {
      handleHamburgerChange();
    }
    handleMenuInfo({
      menuName: "Bulletin Board",
      subMenuName: "",
      sourceComponent: "",
    });
  }, []);

  useEffect(() => {
    return () => {
      if (
        instance?.getActiveAccount() &&
        instance?.getAllAccounts()?.length > 0
      ) {
        setLocalStorage();
      }
    };
  }, [bulletinDetails, hasMoreData, window.scrollY]);

  const setLocalStorage = () => {
    localStorage.setItem(
      "bulletinBoardFromManageTeam",
      JSON.stringify({
        bulletinDetails: bulletinDetails,
        hasMoreData: hasMoreData,
        yOffset: window.scrollY,
        scrolledCardId: undefined,
      })
    );
  };

  const setSideBarMenu = () => {
    menuState?.menu?.name !== "Bulletin Board" &&
      handleChangeNav({
        section: {
          id: sideNavMainMenuData[0].id,
          name: "",
        },
        menu: {
          id: sideNavMainMenuData[0].menu[0].id,
          name: "Bulletin Board",
        },
        submenu: {
          id: "",
          name: "",
        },
        data: "",
        to: "/",
      });
  };
  const handleOnCardClick = (
    event,
    params,
    contentTypeId,
    hasAccess = false
  ) => {
    // Prevent event bubbling
    event.stopPropagation();

    // if (detailsPageAccess == true) {
    if (hasAccess) {
      // navigate(`/taxalerts/details/${params.metaDataId}`);
      //   console.log("Conferences Card Clicked");
      //   let allCardsWithConfiguration = {
      //     allCardsData: allCardsData,
      //     cardFetchDetails: cardFetchDetails,
      //     contentTypeLevelConfig: contentTypeLevelFlags,
      //   };
      //   localStorage.setItem(
      //     props.name,
      //     JSON.stringify(allCardsWithConfiguration)
      //   );
      if (params?.contentInfo) {
        params?.contentInfo?.map((item) => {
          viewCounterFunc(
            params,
            item.contentSourceType === "URL",
            item.contentSourceType === "URL"
          );

          if (item.contentSourceType === "URL") {
            // go to link outside of the teams
            try {
              let urlToBeOpened = atob(item.url);
              userInfo.loggedInWeb === true
                ? window.open(urlToBeOpened, "_blank")
                : app.getContext().then((item) => {
                    if (item.app.host.name == "Teams") {
                      app.openLink(urlToBeOpened);
                    } else {
                      window.open(urlToBeOpened, "_blank");
                    }
                  });
            } catch (e) {
              console.log(e);
            }
          } else if (item.contentSourceType === "Template") {
            //downloadPdf(params.metaDataId, props.contentTypeId, item.content);
            navigate(
              `/pdfDetails/${params.metaDataId}/${contentTypeId}/${
                params?.contentSecurity?.availability?.domainLevel ==
                Constant.GLOBAL
                  ? true
                  : false
              }?prevPath=${window.location.pathname}&BB=true&pinMetadataId=${
                params?.pinDetail?.pinMetaDataId
              }`
            );
            // viewCounterFunc(
            //   params,
            //   item.contentSourceType === "Template",
            //   item.contentSourceType === "Template"
            // );
            // handleOpenPdf(params, contentTypeId);
          } else if (
            item?.contentSourceType?.toLowerCase() === "HTML"?.toLowerCase()
          ) {
            //navigate to detail page
            navigate(
              `/itemDetails/${params.metaDataId}/${contentTypeId}/${
                params?.contentSecurity?.availability?.domainLevel ==
                Constant.GLOBAL
                  ? true
                  : false
              }?prevPath=${window.location.pathname}&BB=true&pinMetadataId=${
                params?.pinDetail?.pinMetaDataId
              }`
            );
          } else if (item.contentSourceType === "PBIX") {
            navigate(
              `/visualizationlibrary/details/${params.metaDataId}/${
                params?.contentSecurity?.availability?.domainLevel ==
                Constant.GLOBAL
                  ? true
                  : false
              }?prevPath=${window.location.pathname}&BB=true&pinMetadataId=${
                params?.pinDetail?.pinMetaDataId
              }`,
              {
                state: {
                  templateId:
                    params?.contentInfo?.[0].content?.requestJson?.template?.id,
                  uniqueId:
                    params?.contentInfo?.[0].content?.requestJson
                      ?.dataInstanceId,
                  companyId: userInfo.teams.companyId,
                },
              }
            );
          } else if (
            item.contentSourceType?.toLowerCase() === "App".toLowerCase()
          ) {
            const contentTypeNavigationMap = {
              [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK]: {
                path: "/apps/LibrarySearchPage",
                state: (internalAppDetails) => ({
                  appDetails: internalAppDetails,
                }),
              },
              [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES]: {
                path: "/apps/LibrarySearchPage",
                state: (internalAppDetails) => ({
                  appDetails: internalAppDetails,
                }),
              },
              [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]: {
                path: "/apps/LibraryGrid",
                state: (internalAppDetails) => ({
                  appDetails: internalAppDetails,
                  formJson: null,
                  templateDetails: { templateId: "10" },
                }),
              },
            };
            let internalAPPContentTypeID =
              Constant.INTERNAL_APPS_CONTENT_TYPE_IDS[item.appName] || 0;
            let internalAppDetails = {
              breadCrumb: params.title ?? "",
              contentTypeId: internalAPPContentTypeID ?? 0,
              isInternal: params.isInternal ?? false,
              appID: item.appId ?? "",
              appName: item.appName ?? "",
              isGlobal:
                params?.contentSecurity?.availability?.domainLevel ==
                Constant.GLOBAL
                  ? true
                  : false,
              version: item?.searchTemplateVersion ?? 0,
            };
            handleChangeNav({
              section: {
                id: sideNavMainMenuData[3]?.id,
                name: "",
              },
              menu: {
                id: sideNavMainMenuData[3]?.menu[0]?.id,
                name: "Apps",
              },
              submenu: {
                id: sideNavMainMenuData[3]?.menu[0]?.submenu[0]?.id,
                name: internalAppDetails?.breadCrumb,
              },
              data: "",
              to: "/apps/LibraryGrid",
            });
            const navigationDetails =
              contentTypeNavigationMap[internalAPPContentTypeID];
            if (navigationDetails) {
              handleLibrarySearchFormJsonData({
                columnFilteringApplied: false,
                appliedFilteredValues: {},
                searchQuery: "",
                totalRecordsCount: 0,
                prevPath: "/",
              });
              handleSearchAndFilterColumnsData({
                clearData: true,
              });
              navigate(navigationDetails.path, {
                state: navigationDetails.state(internalAppDetails),
              });
            }
          }
        });
      } else {
        viewCounterFunc(params, false);
        navigate(
          `/itemDetails/${params.metaDataId}/${contentTypeId}/${
            params?.contentSecurity?.availability?.domainLevel ==
            Constant.GLOBAL
              ? true
              : false
          }?prevPath=${window.location.pathname}&BB=true&pinMetadataId=${
            params?.pinDetail?.pinMetaDataId
          }`
        );
      }
    } else {
      handleNotificationMsgsData({
        showMsgBar: true,
        started: false,
        completed: true,
        msg: Constant.ACCESSDENIED_DETAILSPAGE,
        type: "fail",
        isFailed: false,
      });
    }

    // console.log(params?.pinDetail?.pinMetaDataId);
  };

  // const handleOpenPdf = async (params, contentTypeId) => {
  //   try {
  //     setPdfRender(true);
  //     const response = await openPdfInNewTab(
  //       params.metaDataId,
  //       contentTypeId,
  //       params?.contentSecurity?.availability?.domainLevel === Constant.GLOBAL,
  //       userInfo?.teams?.companyId
  //     );

  //     // Handle the response object here
  //     if (response.statusCode === "STCS001" && response.pdfUrl !== "") {
  //       setPdfRender(false);
  //       if (userInfo.loggedInWeb) {
  //         window.open(response.pdfUrl, "_blank");
  //       } else {
  //         app.openLink(response.pdfUrl);
  //       }
  //     } else if (response.statusCode === "STCS002") {
  //       setPdfRender(false);
  //       handleNotificationMsgsData({
  //         showMsgBar: true,
  //         started: false,
  //         completed: true,
  //         msg: Constant.ContentNotAvailable,
  //         type: "fail",
  //         isFailed: false,
  //       });
  //     }
  //   } catch (error) {
  //     setPdfRender(false);
  //     console.error("Error opening PDF:", error);
  //   }
  // };

  const viewCounterFunc = (param, callAPI = true, updateState = false) => {
    let paramViewCounter = {
      metaDataId: param.metaDataId,
    };
    callAPI &&
      postData(
        paramViewCounter,
        APIEndPoints.CONTENT_VIEW_COUNTER(
          param.contentTypeId,
          param?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL
            ? true
            : false,
          userInfo?.teams?.companyId
        )
      )
        .then((res) => {
          if (res.data.statusCode == "STCS002" || res.data == "") {
            handleNotificationMsgsData({
              showMsgBar: true,
              started: false,
              completed: true,
              msg: Constant.ContentNotAvailable,
              type: "fail",
              isFailed: false,
            });
          } else {
            TrackEvent(TelemetryEventNames.UCC_CONTENT_VIEW_EVENT, userInfo, {
              SectionName:
                param.contentTypeId == 4
                  ? TelemetryEventNames.SECTION_TYPE_MY_TECHNOLOGY
                  : TelemetryEventNames.SECTION_TYPE_CONTENT,
              ContentTypeId: param.contentTypeId?.toString() ?? "",
              ContentTypeName:
                getContentTypeNamesById2(param.contentTypeId) ?? "",
              MetadataId: param.metaDataId?.toString(),
              UniqueId: param.contentUniqueId ?? "",
              Title: param.title ?? "",
              PublishDate: param.publishedDate?.toString() ?? "",
              LatestViewCount:
                res?.data?.resultData?.viewCount?.toString() ?? "",
            });
          }
        })
        .catch((err) => {
          console.log(err);
          handleNotificationMsgsData({
            showMsgBar: true,
            started: false,
            completed: true,
            msg: err?.response?.data?.Errors,
            type: "fail",
            isFailed: false,
          });
        });

    //console.log(bulletinDetails);
    let updated = updateMetaDataCounts(
      param.metaDataId,
      bulletinDetails,
      undefined,
      1
    );
    updateState && setBulletinDetails(updated);
    localStorage.setItem(
      "bulletinBoard",
      JSON.stringify({
        bulletinDetails: updated,
        hasMoreData: hasMoreData,
        yOffset: window.scrollY,
        scrolledCardId: param?.pinDetail?.pinMetaDataId,
      })
    );
  };

  const handleBulletinDetails = (props) => {
    setBulletinDetails((prev) => ({
      ...prev,
      ...props,
    }));
  };

  const getDataFromCache = () => {
    let dataItem = getUrlParams("fromManageTeam")
      ? JSON.parse(localStorage.getItem("bulletinBoardFromManageTeam"))
      : JSON.parse(localStorage.getItem("bulletinBoard"));
    if (!dataItem) {
      getDataFromAPI();
      return;
    }
    setIsFetching(false);
    setBulletinDetails(dataItem?.bulletinDetails);
    setHasMoreData(dataItem?.hasMoreData);
    setScrolledCardId(dataItem?.scrolledCardId);
    localStorage.removeItem("bulletinBoard");
    localStorage.removeItem("bulletinBoardFromManageTeam");
    smoothScrollToYOffsetWithRetry(dataItem);
  };

  const smoothScrollToYOffsetWithRetry = (dataItem) => {
    setTimeout(() => {
      const scrollToOptions = {
        top: dataItem?.yOffset,
        behavior: "smooth",
      };
      window.scrollTo(scrollToOptions);
      window.history.replaceState(null, "", "");
    }, 2000);
  };

  const getDataFromAPI = () => {
    // console.log(userInfo.teams.companyId);
    if (userInfo.teams.companyId !== null) {
      //  console.log("inside fetching it");
      setBulletinDetails((prevData) => {
        return {
          ...prevData,
          cardData: [],
        };
      });
      bulletinDetails.pageNumber === 1
        ? fetchData(true)
        : handleBulletinDetails({
            pageNumber: 1,
          });
    }
  };
  const fetchData = (firstPage = false, pageNumber) => {
    setIsFetching(true);
    // setCombinedData(mockBulletinCards, firstPage);
    // setIsFetching(false);
    setAccessDenied(false);
    postData(
      {
        pageLength: bulletinDetails.pageLength,
        pageNumber:
          pageNumber != undefined && pageNumber > 0
            ? pageNumber
            : bulletinDetails.pageNumber,
      },
      APIEndPoints.GET_PINNED_CARDS(userInfo.teams.companyId)
    )
      .then((res) => {
        // combineFeatureFlags(res.data, firstPage);
        // combineSections(res.data, firstPage);
        if (res?.data?.pinnedMetadata) {
          setIsFetching(false);
          setCombinedData(res.data, firstPage);
          handleBulletinDetails({ pinMetaData: res?.data?.pinnedMetadata });
        } else {
          setIsFetching(false);
          setHasMoreData(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (
          error?.response?.data?.Errors[0]?.toLowerCase() == "access denied."
        ) {
          setAccessDenied(true);
        }
        setIsFetching(false);
        setHasMoreData(false);
      });
  };

  const setCombinedData = (data, firstPage = false) => {
    let combinedData = getCombinedData(data);
    if (combinedData?.length > 0) {
      handleBulletinDetails(
        firstPage
          ? { cardData: combinedData }
          : {
              cardData: bulletinDetails?.cardData.concat(combinedData),
            }
      );
      // setHasMoreData(combinedData?.length >= bulletinDetails?.pageLength);
    }
    if (combinedData?.length < 3) {
      data?.pinnedMetadata?.length !== 0 &&
        handleBulletinDetails({
          pageNumber: bulletinDetails.pageNumber + 1,
        });
    }
    // else {
    //     setHasMoreData(false);
    // }
    setHasMoreData(data?.pinnedMetadata?.length !== 0);
    setIsFetching(false);
  };

  const getCombinedData = (data) => {
    const sortedContentArray = data?.contentMetadata
      ?.flatMap((item) => {
        const contentResult = JSON.parse(item?.contentResult);
        return contentResult?.[0].resultData?.sections?.all?.map(
          (contentItem) => ({
            ...contentItem,
            contentTypeId: item?.contentTypeId,
          })
        );
      })
      .filter((i) => i !== undefined)
      .map((newItem) => {
        const { contentTypeId, metaDataId } = newItem;
        // const pinDetailKey = `${contentType}_${metaDataId}`;
        const matchingPin = find(data?.pinnedMetadata, {
          contentTypeId: contentTypeId,
          metadataId: metaDataId,
        });
        const matchingConfig = find(data?.configuration, {
          contentTypeId: contentTypeId,
        });

        const pinDetail = matchingPin
          ? JSON.parse(matchingPin?.pinDetail)
          : null;
        const config = matchingConfig ? { ...matchingConfig } : null;

        return { ...newItem, pinDetail, config };
      })
      .orderBy((item) => -new Date(item?.pinDetail?.createdDetails?.createdOn))
      .value();
    return sortedContentArray?.filter((item) => item?.pinDetail !== null);
  };

  const handleUnpin = (metaDataId, contentTypeId) => {
    let data = reject(
      bulletinDetails.cardData,
      (item) =>
        item.contentTypeId === contentTypeId && item.metaDataId === metaDataId
    );
    handleBulletinDetails({ cardData: data });
    // data?.length < 1 && fetchData(true);
    data?.length < 4 &&
      hasMoreData &&
      handleBulletinDetails({
        pageNumber: bulletinDetails.pageNumber + 1,
      });
  };

  const EndMessage = () => {
    return isFetching ? (
      <div className="bulletinBoardLoader">
        <Loader />
      </div>
    ) : bulletinDetails.cardData?.length > 0 ? (
      <>
        <Divider
          content="You have seen it all"
          important
          style={{ marginBottom: "20px" }}
        />
      </>
    ) : (
      <></>
    );
  };
  const BottomLoader = () => {
    return (
      <div
        className="bulletinBoardLoader"
        style={
          bulletinDetails.cardData?.length > 0
            ? { height: "max-content" }
            : { height: "50%" }
        }
      >
        <Loader />
      </div>
    );
  };

  const handleAPIError = (isAccessDenied) => {
    if (isAccessDenied) {
      setAccessDenied(true);
    }
  };
  const updateEachSection = (updatedCardData, id, sectionName, isVoted) => {
    let allCardsDataObj = [...bulletinDetails.cardData];
    if (allCardsDataObj?.length > 0) {
      let filteredCard = allCardsDataObj?.filter((item) => {
        return item?.metaDataId === id;
      })?.[0];
      if (filteredCard) {
        let index = allCardsDataObj?.findIndex(
          (item) => item?.metaDataId === id
        );
        let contentInfoObj = allCardsDataObj?.contentInfo?.[0];
        let contentCustomDataObj = allCardsDataObj?.contentCustomData;
        contentInfoObj = {
          ...contentInfoObj,
          content: updatedCardData?.contentInfo?.[0]?.content,
        };
        contentCustomDataObj = {
          ...contentCustomDataObj,
          tileCustomData: updatedCardData?.contentCustomData?.tileCustomData,
        };
        filteredCard = {
          ...filteredCard,
          contentCustomData: contentCustomDataObj,
        };
        filteredCard["contentInfo"][0] = contentInfoObj;
        filteredCard["customData"]["userLevel"]["voted"] =
          isVoted == true || isVoted == false ? isVoted : true;
        allCardsDataObj[index] = filteredCard;
        handleBulletinDetails({ cardData: allCardsDataObj });
      }
    }
  };
  const handleReload = async () => {
    try {
      let topbarHeight = document
        ?.getElementById("topbar")
        ?.getBoundingClientRect().height;
      scrollIntoViewWithOffset(
        getContentTypePathsById(location?.state?.contentType),
        -(topbarHeight + 25 - 10)
      ); //added -10 so the scroll is little bigger so menuState and breadcrumb work correctly.
    } catch (e) {}
    //await fetchData(true);
  };
  const BulletinBoardContainer = useMemo(() => {
    let dates = [];
    return (
      <div
        className="BulletinBoardContainer"
        id="BulletinBoardContainer"
        data-testid="BulletinBoardContainer"
      >
        {bulletinDetails.cardData
          ?.filter(
            (item) => item?.pinDetail !== null && item?.isPinned !== false
          )
          ?.map((cardItem, index) => {
            let contentTypeId = cardItem?.contentTypeId;
            let createdDate = getDateFromUTC3(
              cardItem?.pinDetail?.createdDetails?.createdOn
            );
            dates.push(createdDate);
            //let featureFlagDetails = cardItem?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL ? cardItem?.config?.subContentTypes[0]?.contentCategories?.[0]?.featureFlagsDetails : cardItem?.config?.subContentTypes[0]?.contentCategories?.[1]?.featureFlagsDetails;
            let featureFlagDetails =
              cardItem?.config?.subContentTypes[0]?.contentCategories?.[1]
                ?.featureFlagsDetails;
            return (
              <BulletinCard
                key={cardItem?.metaDataId}
                item={cardItem}
                contentTypeId={contentTypeId}
                config={featureFlagDetails}
                onClick={(event) =>
                  handleOnCardClick(
                    event,
                    cardItem,
                    contentTypeId,
                    // cardItem?.contentSecurity?.availability?.domainLevel == Constant.GLOBAL ?
                    //     cardItem?.config?.contentCategories?.[0]?.featureFlagsDetails?.detailsPage :
                    cardItem?.config?.contentCategories?.[1]
                      ?.featureFlagsDetails?.detailsPage
                  )
                }
                showDivider={dates.filter((i) => i === createdDate).length < 2}
                handleUnpin={handleUnpin}
                scrolledCardId={scrolledCardId}
                updateEachSection={updateEachSection}
                sectionName={["all"]}
                hasMoreData={hasMoreData}
              />
            );
          })}
      </div>
    );
  }, [bulletinDetails.cardData]);

  if (errorAccessDenied) return <UnauthorizedPage />;

  return userInfo?.teams?.featureFlagsObj["Bulletin Board"]?.isEnabled ===
    true ? (
    <>
      {" "}
      {/* {pdfRender && (
        <div>
          <ReusableLoader isComponentBody={true} isOverlay={true} loaderCommonPosition={true}/>
        </div>
      )} */}
      <div className="BulletinBoard" id="/">
        {!isFetching &&
        bulletinDetails.cardData?.length < 1 &&
        bulletinDetails.pinMetaData?.length < 1 ? (
          <WelcomeScreen />
        ) : bulletinDetails.cardData?.length < 1 && isFetching ? (
          <div style={{ marginTop: "20%" }}>
            {" "}
            <Loader />
          </div>
        ) : (
          <>
            {bulletinDetails.cardData?.length > 0 ? (
              <InfiniteScroll
                isFetching={isFetching}
                dataLength={bulletinDetails.cardData?.length}
                next={() =>
                  !isFetching &&
                  handleBulletinDetails({
                    pageNumber: bulletinDetails.pageNumber + 1,
                  })
                }
                hasMore={hasMoreData}
                loader={<BottomLoader />}
                endMessage={<EndMessage />}
              >
                {BulletinBoardContainer}
              </InfiniteScroll>
            ) : (
              <WelcomeScreen />
            )}
          </>
        )}
      </div>
      {location?.state && location?.state?.companyId > 0 && (
        <DeepLinkPopup
          location={{ ...location.state }}
          allCardsData={bulletinDetails?.cardData}
          updateEachSection={updateEachSection}
          handleOnCardClick={handleOnCardClick}
          name={getContentTypeNamesById2(location?.state?.contentType) ?? ""}
          path={"/"}
          contentType={
            location?.state?.contentType &&
            parseInt(location?.state?.contentType)
          }
          isBulletinBoard={true}
          showDivider={false}
          handleUnpin={handleUnpin}
          sectionName={["all"]}
          hasMoreData={false}
          getCombinedData={getCombinedData}
          fetchData={fetchData}
          handleAPIError={handleAPIError}
          // handleState={handleState}
        />
      )}
    </>
  ) : (
    <></>
  );
};

export default BulletinBoard;
