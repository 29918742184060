export const convertDate = (fieldContent, isDateTimeFormatRequired = true) => {
  if (fieldContent === null) {
    return '';
  }
  // Function to parse different date formats
  const parseDate = (dateString) => {
    const dateFormats = [
      { regex: /^(\d{2})\/(\d{2})\/(\d{4})$/, format: 'DD/MM/YYYY' },
      { regex: /^(\d{2})\/(\d{2})\/(\d{4})$/, format: 'MM/DD/YYYY' },
      { regex: /^(\d{4})-(\d{2})-(\d{2})$/, format: 'YYYY-MM-DD' },
      { regex: /^(\d{4})\/(\d{2})\/(\d{2})$/, format: 'YYYY/MM/DD' },
      { regex: /^(\d{2})-(\d{2})-(\d{4})$/, format: 'DD-MM-YYYY' },
      { regex: /^(\d{2})-(\d{2})-(\d{4})$/, format: 'MM-DD-YYYY' },
      // Add more formats as needed
    ];
    for (const { regex, format } of dateFormats) {
      const match = dateString.match(regex);
      if (match) {
        const [_, part1, part2, part3] = match;
        if (format === 'MM/DD/YYYY' || format === 'MM-DD-YYYY') {
          return new Date(`${part3}-${part1}-${part2}T00:00:00Z`);
        } else if (format === 'DD/MM/YYYY' || format === 'DD-MM-YYYY') {
          return new Date(`${part3}-${part2}-${part1}T00:00:00Z`);
        } else if (format === 'YYYY-MM-DD' || format === 'YYYY/MM/DD') {
          return new Date(`${part1}-${part2}-${part3}T00:00:00Z`);
        }
      }
    }
    return new Date(dateString); // Fallback to default parsing
  };

  const date = parseDate(fieldContent);

  // Handle invalid date
  if (isNaN(date.getTime())) {
    return fieldContent;
  }

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZone: timeZone,
    hour12: false,
  };
  const formattedDate = date.toLocaleString('en-US', options);
  const [datePart, timePart] = formattedDate.split(', ');
  const [month, day, year] = datePart.split('/');
  const [hour, minute, second] = timePart.split(':');

  return `${month}/${day}/${year}${isDateTimeFormatRequired ? ` ${hour}:${minute}:${second}` : ''}`;
};
export const formatDate= (dateString, format) => {
  if (!dateString) {
    return null;
  }
  // Parse the date string in MM/DD/YYYY format
  const [month, day, year] = dateString.split('/').map(Number);
  const date = new Date(year, month - 1, day);

  // Extract date components
  const yyyy = date.getFullYear();
  const MM = String(date.getMonth() + 1).padStart(2, '0');
  const dd = String(date.getDate()).padStart(2, '0');
  const HH = String(date.getHours()).padStart(2, '0');
  const mm = String(date.getMinutes()).padStart(2, '0');
  const ss = String(date.getSeconds()).padStart(2, '0');
  const SSSSSSS = String(date.getMilliseconds()).padStart(3, '0') + '000000'.slice(0, 4); // Pad to 7 digits

  // Replace format tokens with actual date components
  switch (format) {
    case 'YYYY-MM-DD':
      return `${yyyy}-${MM}-${dd}`;
    case 'DD/MM/YYYY':
      return `${dd}/${MM}/${yyyy}`;
    case 'MM/DD/YYYY':
        return `${MM}/${dd}/${yyyy}`;
    case 'DD-MM-YYYY':
      return `${dd}-${MM}-${yyyy}`;
    case 'MM/DD/YY':
      return `${MM}/${dd}/${String(yyyy).slice(2)}`;
    case 'MM-DD-YY':
      return `${MM}-${dd}-${String(yyyy).slice(2)}`;
    case 'DD MMM YYYY':
      return `${dd} ${date.toLocaleString('default', { month: 'short' })} ${yyyy}`;
    case 'MMM DD, YYYY':
      return `${date.toLocaleString('default', { month: 'short' })} ${dd}, ${yyyy}`;
    case 'YYYY/MM/DD':
      return `${yyyy}/${MM}/${dd}`;
    case 'DD MMM YY':
      return `${dd} ${date.toLocaleString('default', { month: 'short' })} ${String(yyyy).slice(2)}`;
    case 'MMM DD YYYY':
      return `${date.toLocaleString('default', { month: 'short' })} ${dd} ${yyyy}`;
    case 'YYYY-MM-DD HH:mm:ss.SSSSSSS':
      return `${yyyy}-${MM}-${dd}`;
    case 'YYYY-MM-DD HH:mm:ss':
      return `${yyyy}-${MM}-${dd}`;
    case 'MMMM DD, YYYY':
      return `${date.toLocaleString('default', { month: 'long' })} ${dd}, ${yyyy}`;
    default:
      return null;
  }
};
export const identifyDateFormat = (dateString)=> {
  const formats = [
      { regex: /^\d{4}-\d{2}-\d{2}$/, format: 'YYYY-MM-DD' },
      { regex: /^\d{2}\/\d{2}\/\d{4}$/, format: 'DD/MM/YYYY' },
      { regex: /^\d{2}-\d{2}-\d{4}$/, format: 'DD-MM-YYYY' },
      { regex: /^\d{2}\/\d{2}\/\d{2}$/, format: 'MM/DD/YY' },
      { regex: /^\d{2}-\d{2}-\d{2}$/, format: 'MM-DD-YY' },
      { regex: /^\d{2} \w{3} \d{4}$/, format: 'DD MMM YYYY' },
      { regex: /^\w{3} \d{2}, \d{4}$/, format: 'MMM DD, YYYY' },
      { regex: /^\d{4}\/\d{2}\/\d{2}$/, format: 'YYYY/MM/DD' },
      { regex: /^\d{2} \w{3} \d{2}$/, format: 'DD MMM YY' },
      { regex: /^\w{3} \d{2} \d{4}$/, format: 'MMM DD YYYY' },
      { regex: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}.\d+$/, format: 'YYYY-MM-DD HH:mm:ss.SSSSSSS' },
      { regex: /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/, format: 'YYYY-MM-DD HH:mm:ss' },
      { regex: /^\w{4,9} \d{2}, \d{4}$/, format: 'MMMM DD, YYYY' },
      { regex: /^\d{2}\/\d{2}\/\d{4}$/, format: 'MM/DD/YYYY' }, // Added regex for MM/DD/YYYY
      // Add more formats as needed
  ];

  for (let i = 0; i < formats.length; i++) {
      if (formats[i].regex.test(dateString)) {
          return formats[i].format;
      }
  }

  return 'Unknown format';
}
export const convertStringToDate = (dateString) => {
  if (dateString == null || typeof dateString !== 'string') {
    return null;
  }
  const partString = dateString.split("-");
  if (partString.length === 3) {
    const year = parseInt(partString[2], 10);
    const month = parseInt(partString[0], 10) - 1; // Month (0-based index)
    const day = parseInt(partString[1], 10);

    if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
      return new Date(year, month, day);
    }
  }
  return null;
};
export const hasDuplicates = (array) => {
  return new Set(array).size !== array.length;
};
export const handleStringArrayFormat = (fieldContent) => {
  if (!fieldContent) {
    return "";
  }
  // Replace commas with commas followed by a space if there are multiple values
  return fieldContent.includes(',')
    ? fieldContent.split(',').join(', ')
    : fieldContent;
};
export const utils = { convertDate, formatDate, identifyDateFormat, convertStringToDate,hasDuplicates,handleStringArrayFormat}
